import { useEffect, useState, useCallback } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../store/objectivesStore";
import { debounce } from "lodash";

const useReportObjectives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);
	const [pageUpdated, setPageUpdated] = useState(false);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const {
		objectives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		fetchSubObjectives,
		fetchChildren,
		fetchObjectivesReportTable,
		totalPages,
		loading,
		setLoading,
	} = objectivesStoreZustand();

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase || type) {
			setSecondaryCurrentPage(1);
		}
	}, [searchPhrase, setSecondaryCurrentPage, type]);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const fetchAllSubObjectives = useCallback(() => {
		const { objectives } = objectivesStoreZustand.getState();
		objectives.forEach((objective) => {
			if (objective.type === "multi") {
				fetchSubObjectives({ wholesalerId }, objective._id);
			}
		});
	}, [wholesalerId, fetchSubObjectives]);

	const fetchAllChildrenByUser = useCallback(async () => {
		const { objectives } = objectivesStoreZustand.getState();
		objectives.forEach((objective) => {
			if (objective.type === "grouped") {
				fetchChildren({wholesalerId}, objective._id)
			}
		})
	}, [wholesalerId, fetchChildren]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchObjectivesReportTable({
				wholesalerId,
				page: secondaryCurrentPage,
				limit: secondaryPageSize,
				searchPhrase: debouncedSearchPhrase,
				type,
			});

			const { objectives } = objectivesStoreZustand.getState();

			if (objectives.length > 0) {
				if (
					objectives.some((objective) => objective.type === "multi")
				) {
					fetchAllSubObjectives();
				}

				if (
					objectives.some((objective) => objective.type === "grouped")
				) {
					await fetchAllChildrenByUser();
				}
			}
		};

		if (pageUpdated || secondaryCurrentPage !== undefined) {
			fetchData().then(() => setPageUpdated(false));
		}
	}, [
		wholesalerId,
		secondaryCurrentPage,
		secondaryPageSize,
		debouncedSearchPhrase,
		type,
		fetchObjectivesReportTable,
		fetchAllSubObjectives,
		fetchAllChildrenByUser,
		pageUpdated,
	]);

	return {
		objectives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages,
		loading,
		setLoading,
	};
};

export default useReportObjectives;
