import { useEffect, useState, useCallback } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../store/incentivesStore";
import { debounce } from "lodash";

const useReportIncentives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);
	const [pageUpdated, setPageUpdated] = useState(false);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const {
		incentives,
		archivedIncentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		fetchSubIncentives,
		fetchChildren,
		fetchIncentivesReportTable,
		totalPages,
		loading,
		setLoading,
	} = incentivesStoreZustand();

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase || type) {
			setSecondaryCurrentPage(1);
		}
	}, [searchPhrase, setSecondaryCurrentPage, type]);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const fetchAllSubIncentives = useCallback(() => {
		const { incentives } = incentivesStoreZustand.getState();
		incentives.forEach((incentive) => {
			if (incentive.type === "multi") {
				fetchSubIncentives({ wholesalerId }, incentive._id);
			}
		});
	}, [wholesalerId, fetchSubIncentives]);

	const fetchAllChildrenByUser = useCallback(async () => {
		const { incentives } = incentivesStoreZustand.getState();
		incentives.forEach((incentive) => {
			if (incentive.type === "grouped") {
				fetchChildren({wholesalerId}, incentive._id)
			}
		})
	}, [wholesalerId, fetchChildren]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchIncentivesReportTable({
				wholesalerId,
				page: secondaryCurrentPage,
				limit: secondaryPageSize,
				searchPhrase: debouncedSearchPhrase,
				type,
			});

			const { incentives } = incentivesStoreZustand.getState();

			if (incentives.length > 0) {
				if (
					incentives.some((incentive) => incentive.type === "multi")
				) {
					fetchAllSubIncentives();
				}

				if (
					incentives.some((incentive) => incentive.type === "grouped")
				) {
					await fetchAllChildrenByUser();
				}
			}
		};

		if (pageUpdated || secondaryCurrentPage !== undefined) {
			fetchData().then(() => setPageUpdated(false));
		}
	}, [
		wholesalerId,
		secondaryCurrentPage,
		secondaryPageSize,
		debouncedSearchPhrase,
		type,
		fetchIncentivesReportTable,
		fetchAllSubIncentives,
		fetchAllChildrenByUser,
		pageUpdated,
	]);

	return {
		incentives,
		archivedIncentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages,
		loading,
		setLoading,
	};
};

export default useReportIncentives;
