import { useEffect, useState } from "react";
import { objectivesStoreZustand } from "../store/objectivesStore";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { debounce } from "lodash";

const useArchivedReportObjectives = ({
	searchPhrase,
	type,
}: {
	searchPhrase?: string;
	type?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [archivedSecondaryPageSize, setArchivedSecondaryPageSize] =
		useState(10);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);
	
	const {
		archivedObjectives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
	} = objectivesStoreZustand();

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase || type) {
			setArchivedSecondaryCurrentPage(1);
		}
	}, [searchPhrase, setArchivedSecondaryCurrentPage, type]);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchArchivedObjectives({
			wholesalerId,
			page: archivedSecondaryCurrentPage,
			limit: archivedSecondaryPageSize,
			searchPhrase: debouncedSearchPhrase,
			type,
		});
	}, [
		wholesalerId,
		archivedSecondaryCurrentPage,
		archivedSecondaryPageSize,
		debouncedSearchPhrase,
		type,
	]);

	return {
		archivedObjectives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedSecondaryPageSize,
		setArchivedSecondaryPageSize,
		archivedTotalPages: objectivesStoreZustand(
			(state) => state.archivedTotalPages
		),
		loadingArchived: objectivesStoreZustand(
			(state) => state.loadingArchived
		),
		setLoadingArchived: objectivesStoreZustand(
			(state) => state.setLoadingArchived
		),
	};
};

export default useArchivedReportObjectives;