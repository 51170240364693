import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";
import { debounce } from "lodash";

const useArchivedIncentives = ({
	searchPhrase,
	type,
	tag,
	periodStart,
	periodEnd,
}: {
	searchPhrase?: string;
	type?: string;
	tag?: string;
	periodStart?: any;
	periodEnd?: any;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();

	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [pageSize, setPageSize] = useState(15);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const loadingArchived = incentivesStoreZustand(
		(state) => state.loadingArchived
	);
	const archivedCurrentPage = incentivesStoreZustand(
		(state) => state.archivedCurrentPage
	);
	const setArchivedCurrentPage = incentivesStoreZustand(
		(state) => state.setArchivedCurrentPage
	);
	const archivedTotalPages = incentivesStoreZustand(
		(state) => state.archivedTotalPages
	);

	const archivedIncentives = incentivesStoreZustand(
		(state) => state.archivedIncentives
	);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase) {
			setArchivedCurrentPage(1);
		}
	}, [searchPhrase, setArchivedCurrentPage]);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllSubIncentives = () => {
			const { archivedIncentives } = incentivesStoreZustand.getState();
			archivedIncentives.forEach((incentive) => {
				if (incentive.type === "multi") {
					incentivesStoreZustand
						.getState()
						.fetchSubIncentives(
							{ wholesalerId },
							incentive._id,
							true
						);
				}
			});
		};
		incentivesStoreZustand.getState().fetchArchivedIncentives(
			{
				wholesalerId,
				page: archivedCurrentPage,
				limit: pageSize,
				searchPhrase: debouncedSearchPhrase,
				type,
				tag,
				periodStart,
				periodEnd,
			},
			fetchAllSubIncentives
		);
	}, [
		wholesalerId,
		archivedCurrentPage,
		pageSize,
		debouncedSearchPhrase,
		type,
		tag,
		periodStart,
		periodEnd,
	]);

	return {
		archivedIncentives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
		setPageSize,
	};
};

export default useArchivedIncentives;