import { useEffect, useMemo, useState } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";

import { isIncentive, ManualProductsFilters } from "../types/commonObjIncTypes";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { Objective } from "../types/objectiveTypes";
import { Incentive } from "../types/incentivesTypes";
import { debounce } from "lodash";

const useSelectedProducts = ({
	page,
	item,
	filters,
}: {
	page: number;
	item: Incentive | Objective;
	filters: ManualProductsFilters;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;
	let typeKey: string;

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}
	const selectedLoading = commonObjIncStoreZustand(
		(state) => state.selectedProductsLoading
	);

	const selectedProducts = commonObjIncStoreZustand(
		(state) => state.selectedProducts
	);

	const [debouncedFilters, setDebouncedFilters] =
		useState<ManualProductsFilters>(filters);

	const debouncedUpdateFilters = useMemo(
		() =>
			debounce((updatedFilters: ManualProductsFilters) => {
				setDebouncedFilters(updatedFilters);
			}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdateFilters(filters);
	}, [filters, debouncedUpdateFilters]);

	const updateProducts = (
		newProducts: { productId: string; checked: boolean }[]
	) => {
		commonObjIncStoreZustand
			.getState()
			.updateProducts(
				!isIncentive(item),
				{ [typeKey]: item?._id, wholesalerId },
				newProducts
			);
	};

	useEffect(() => {
		const extendedFilters = { ...debouncedFilters, wholesalerId };

		commonObjIncStoreZustand.getState().fetchSelectedProducts({
			page,
			wholesalerId,
			[typeKey]: item?._id,
			isObjective: !isIncentive(item),
			query: extendedFilters,
		});
	}, [item, page, query, typeKey, wholesalerId, debouncedFilters]);

	return {
		selectedProducts,
		selectedLoading,
		selectedTotalPages:
			commonObjIncStoreZustand.getState().selectedProductsTotalPages,
		updateProducts,
	};
};

export default useSelectedProducts;
