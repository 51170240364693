import { useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";
import { debounce } from "lodash";

const useIncentives = ({
	searchPhrase,
	type,
	tag,
	periodStart,
	periodEnd,
}: {
	searchPhrase?: string;
	type?: string;
	tag?: string;
	periodStart?: any;
	periodEnd?: any;
}) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(15);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const loading = incentivesStoreZustand((state) => state.loading);

	const currentPage = incentivesStoreZustand((state) => state.currentPage);

	const setCurrentPage = incentivesStoreZustand(
		(state) => state.setCurrentPage
	);

	const setCurrentFavPage = incentivesStoreZustand(
		(state) => state.setCurrentFavPage
	);
	const totalFavPages = incentivesStoreZustand(
		(state) => state.totalFavPages
	);
	const currentFavPage = incentivesStoreZustand(
		(state) => state.currentFavPage
	);

	const totalPages = incentivesStoreZustand((state) => state.totalPages);

	const incentives = incentivesStoreZustand((state) => state.incentives);

	const favoriteIncentives = incentivesStoreZustand(
		(state) => state.favoriteIncentives
	);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase) {
			setCurrentPage(1);
		}
	}, [searchPhrase, setCurrentPage]);

	useEffect(() => {
		const fetchAllSubIncentives = () => {
			const { incentives } = incentivesStoreZustand.getState();
			incentives.forEach((incentive) => {
				if (incentive.type === "multi") {
					incentivesStoreZustand
						.getState()
						.fetchSubIncentives({ wholesalerId }, incentive._id);
				}
			});
		};

		incentivesStoreZustand.getState().fetchIncentives(
			{
				wholesalerId,
				page: currentPage,
				limit: pageSize,
				searchPhrase: debouncedSearchPhrase,
				type,
				tag,
				periodStart,
				periodEnd,
			},
			fetchAllSubIncentives
		);
	}, [
		wholesalerId,
		currentPage,
		pageSize,
		debouncedSearchPhrase,
		type,
		tag,
		periodStart,
		periodEnd,
	]);

	useEffect(() => {
		incentivesStoreZustand.getState().fetchFavoriteIncentives({
			wholesalerId,
			favorite: true,
			page: currentFavPage,
			limit: pageSize,
			searchPhrase: debouncedSearchPhrase,
		});
	}, [wholesalerId, currentFavPage, pageSize, debouncedSearchPhrase]);

	return {
		loading,
		incentives,
		currentPage,
		favoriteIncentives,
		setCurrentPage,
		setPageSize,
		totalPages,
		totalFavPages,
		currentFavPage,
		setCurrentFavPage,
	};
};

export default useIncentives;
