import React, { useEffect, useState } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import useProductsItem from '../Item/useProductsItem';
import { useStyles } from './AccountsProductsList.styles';

interface ProductsListProps {
    itemId: string;
    userId: string;
    isObjective: boolean;
}


export const ProductsList: React.FC<ProductsListProps> = ({ itemId, userId, isObjective }) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const classes = useStyles();

    const {
        products,
        productsLoading,
        productsCurrentPage,
        setProductsCurrentPage,
        productsTotalPages,
        fetchProducts,
    } = useProductsItem(itemId, userId, isObjective, searchTerm);

    const handleClickOpen = () => {
        setOpen(true);
        fetchProducts();
    };

    const handleClose = () => {
        setOpen(false);
        setProductsCurrentPage(1);
    };
    const handleChangePage = (_:React.ChangeEvent<unknown>, value:number) => {
        setProductsCurrentPage(value);  
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        setProductsCurrentPage(1)
    };

    useEffect(() => {
        if (open) { 
            fetchProducts();
        }
    }, [productsCurrentPage, fetchProducts, open]);

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className={classes.center}
            >
                See All
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle className={classes.center}>All Products</DialogTitle>
                <TextField
                    label="Search by name"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className={classes.searchField}
                />
                <DialogContent className={classes.dialogContent}>
                    {productsLoading ? (
                        <Typography className={classes.centerText}>Loading...</Typography>
                    ) : (
                        <>
                            {products.length === 0 ? (
                                    <Typography className={classes.centerText}>No product selected that belongs to this User</Typography>
                            ) : (
                                <>
                                    <List className={classes.list}>
                                        {products.map((product: string, index: number) => (
                                            <ListItem key={index} className={classes.listItem}>
                                                <ListItemText primary={product} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            )}
                        </>
                    )}
                </DialogContent>
                <Pagination
                    className={classes.pagination}
                    count={productsTotalPages}
                    page={productsCurrentPage}
                    onChange={handleChangePage}
                    color="primary"
                />
                <DialogActions className={classes.center}>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
