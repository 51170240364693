import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import { debounce } from "lodash";

const useArchivedObjectives = ({
	searchPhrase,
	type,
	tag,
	periodStart,
	periodEnd,
}: {
	searchPhrase?: string;
	type?: string;
	tag?: string;
	periodStart?: any;
	periodEnd?: any;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(15);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const loadingArchived = objectivesStoreZustand(
		(state) => state.loadingArchived
	);
	const archivedCurrentPage = objectivesStoreZustand(
		(state) => state.archivedCurrentPage
	);
	const setArchivedCurrentPage = objectivesStoreZustand(
		(state) => state.setArchivedCurrentPage
	);
	const archivedTotalPages = objectivesStoreZustand(
		(state) => state.archivedTotalPages
	);
	const archivedObjectives = objectivesStoreZustand(
		(state) => state.archivedObjectives
	);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase) {
			setArchivedCurrentPage(1);
		}
	}, [searchPhrase, setArchivedCurrentPage]);

	useEffect(() => {
		const fetchAllSubObjectives = () => {
			const { archivedObjectives } = objectivesStoreZustand.getState();
			archivedObjectives.forEach((objective) => {
				if (objective.type === "multi") {
					objectivesStoreZustand
						.getState()
						.fetchSubObjectives(
							{ wholesalerId },
							objective._id,
							true
						);
				}
			});
		};
		objectivesStoreZustand.getState().fetchArchivedObjectives(
			{
				wholesalerId,
				page: archivedCurrentPage,
				limit: pageSize,
				searchPhrase: debouncedSearchPhrase,
				type,
				tag,
				periodStart,
				periodEnd,
			},
			fetchAllSubObjectives
		);
	}, [
		wholesalerId,
		archivedCurrentPage,
		pageSize,
		debouncedSearchPhrase,
		type,
		tag,
		periodStart,
		periodEnd,
	]);

	return {
		setPageSize,
		archivedObjectives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
	};
};

export default useArchivedObjectives;