import { useEffect, useState } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";

import { isIncentive } from "../types/commonObjIncTypes";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { Objective } from "../types/objectiveTypes";
import { Incentive } from "../types/incentivesTypes";
import { debounce } from "lodash";

const useSelectedAccounts = ({
	page,
	item,
	accountType,
	premise,
	chains,
	locations,
	searchPhrase,
}: {
	page: number;
	item: Incentive | Objective;
	accountType?: string;
	premise?: string;
	chains?: string[];
	locations?: string[];
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;
	const limit = 7;
	let typeKey: string;

	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}
	const selectedLoading = commonObjIncStoreZustand(
		(state) => state.selectedAccountsLoading
	);
	const selectedAccounts = commonObjIncStoreZustand(
		(state) => state.selectedAccounts
	);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	const updateAccounts = (
		newAccounts: { buyerId: string; checked: boolean }[]
	) => {
		commonObjIncStoreZustand
			.getState()
			.updateAccounts(
				!isIncentive(item),
				{ [typeKey]: item?._id, wholesalerId },
				newAccounts
			);
	};
	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchSelectedAccounts({
			searchPhrase: debouncedSearchPhrase,
			accountType,
			premise,
			chains,
			locations,
			page,
			limit,
			wholesalerId,
			[typeKey]: item?._id,
			isObjective: !isIncentive(item),
		});
	}, [
		accountType,
		page,
		limit,
		wholesalerId,
		typeKey,
		item?._id,
		premise,
		debouncedSearchPhrase,
		item,
		chains,
		locations,
	]);

	return {
		selectedAccounts,
		selectedLoading,
		totalPages:
			commonObjIncStoreZustand.getState().selectedAccountsTotalPages,
		updateAccounts,
	};
};

export default useSelectedAccounts;
